<template>

  <div>
    <h3 class="domeaGreen">{{isAddMode ? "Opret medarbejder": approvedByJura ? "Vis medarbejder" : "Rediger Medarbejder"}}</h3>
<!-- {{JSON.stringify(employee.departmentStatus)}} {{approvedByJura}} -->
<!-- XX{{this.Profiles.length}} -->
    <form @submit.prevent="submit" >
      <fieldset :disabled="this.approvedByJura">
      <vx-card no-shadow class="mt-8" >
        <h2 class="mb-6 domeaGreen">Stamdata</h2>

        <!-- Afdeling (Drop down - udledes af den medarbejder der udfylder arket - med muligheder for at kunne vælge andet) -->
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Afdeling</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-select
              class="w-full"
              placeholder="Vælg afdeling"
              label-placeholder="Vælg afdeling"
              autocomplete
              v-model="selectedDepartments"
            >
              <vs-select-item
                :key="index"
                :value="item.departmentKey"
                :text="item.departmentKey + ' - ' + item.departmentName"
                v-for="(item, index) in departments" 
              />
            </vs-select>
          </div>
        </div>

        <!-- <span v-if="!newProfile && dirtyCurrentProfile" style="color: #f17979; margin-left: 1em">
          Der skal vælges en jobtitel !!
        </span> -->

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Jobtitel</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-select
              placeholder="Vælg jobtitel"
              v-model="newProfile"
              class="profile-prompt-select"
              autocomplete
              :danger="!newProfile && dirtyCurrentProfile"
              danger-text="Der skal vælges en jobtitel"
            >
              <vs-select-item            
                v-for="(profile, index) in currentProfiles"
                :key="index"
                :value="profile"
                :text="profile.organisationsId + ' - ' + profile.type"
              />
            </vs-select>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip :title="getInfoTextsByKey('Jobtitel')" color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Navn</span>
          </div>

          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full"
              v-model="employee.name"
              placeholder="Indtast navn"
              :danger="$v.employee.name.$dirty && !$v.employee.name.required"
              danger-text="Navn skal udfyldes"
            />
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiv ny medarbejders fulde navn." 
            color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Adresse</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full"
              v-model="employee.address"
              placeholder="Indtast adresse"
              :danger="
                $v.employee.address.$dirty && !$v.employee.address.required
              "
              danger-text="Adresse skal udfyldes"
            />
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiv fulde adresse med vejnavn, husnummer, evt. etagenr. og retning, samt postnummer og by."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Privat email</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full"
              type="email"
              v-model="employee.email"
              placeholder="Indtast privat email"
              :danger="$v.employee.email.$dirty && !$v.employee.email.required"
              danger-text="Email skal udfyldes"
            />
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiv ny medarbejders private emailadresse."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Privat telefonnummer</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full"
              v-model="employee.phoneNumber"
              placeholder="Indtast privat telefonnummer"
              :danger="
                $v.employee.phoneNumber.$dirty &&
                !$v.employee.phoneNumber.required
              "
              danger-text="Privat telefonnummer skal udfyldes"
            />
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiv ny medarbejders private telefonnummer. Der kan angives flere numre, adskil med komma. Der kan også angives landekode med '+' foran."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>CPR-nummer</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full"
              v-model="employee.cpr"
              placeholder="Indtast CPR-nummer (uden bindestreg)"
              :danger="$v.employee.cpr.$dirty && (!$v.employee.cpr.required || employee.cpr.length != 10)"
              danger-text="CPR-nummer skal udfyldes og 10 lang (uden bindestreg)"
            />
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiv ny medarbejders cpr.nr med 10 tal ud i et (uden bindestreg)"
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Uddannelse</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full"
              v-model="employee.education"
              placeholder="Indtast uddannelse"
              :danger="
                $v.employee.education.$dirty && !$v.employee.education.required
              "
              danger-text="Uddannelse skal udfyldes"
            />
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiv højeste uddannelsesniveau, eller relevante uddannelse for stillingen."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Ansættes fra</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-select v-model="recruitedFromSelected" class="w-full">
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in recruitedFromOptions"
              />
            </vs-select>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiver om der er tale om en genansættelse af en tidligere medarbejder, en ny medarbejder eller et internt stillingsskifte."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>
         <!-- Allerede ansat -->
        <div v-if="recruitedFromSelected === 'Allerede ansat'" class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Skal anciennitet overføres?</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="radioAnciennitet"
                vs-name="radioAnciennitet"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="radioAnciennitet"
                vs-name="radioAnciennitet"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
            </div>
            <span v-if="radioAnciennitet === null && recruitedFromSelected === 'Allerede ansat'">
              <div style="color: #f17979">Skal vælges</div>
            </span>
          </div>
        </div>

      </vx-card>

      <vx-card no-shadow class="mt-8">
        <h2 class="mb-6 domeaGreen">Vilkår</h2>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Fleksjob?</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="employee.flexjob"
                vs-name="radioFlexjob"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="employee.flexjob"
                vs-name="radioFlexjob"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
            </div>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiver om vedkommende ansættes i fleksjob. Vær opmærksom på at her gælder særlige vilkår ift. løn og arbejdstid. Er du bekendt med særlige skånebehov bedes dette angives her. Dette kan være aftalt udstyr, aftalte arbejdstider eller andet. "
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>
        <div v-if="employee.flexjob" class="vx-row">
          <div class="vx-col sm:w-1/5 w-full"></div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-textarea
                v-model="employee.flexjobRemark"
                class="w-full"
                placeholder="Er der aftalt specielle forhold? "
              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Antal arbejdstimer</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="vx-col sm:w-1/4">
            <vs-input-number v-model="employee.workHours" :step="0.5"/>
            </div>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiver det aftalte antal månedlige timer medarbejderen skal arbejde."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div v-if="$v.employee.workHours.$dirty && !$v.employee.workHours.required"
          class="vx-row mb-10">
          <div class="vx-col sm:w-1/5 w-full"></div>
          <div class="vx-col sm:w-2/5 w-full">
            <div style="color: #f17979">Antal arbejdstimer skal udfyldes</div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Er stillingen med højeste arbejdstid?</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="employee.maxWorkingHours"
                vs-name="radioMaxWorkingHours"
                :vs-value="true"
                class="pr-8"
              >
                Ja
              </vs-radio>
              <vs-radio
                v-model="employee.maxWorkingHours"
                vs-name="radioMaxWorkingHours"
                :vs-value="false"
              >
                Nej
              </vs-radio>
            </div>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Hvis stillingen er med højeste arbejdstid, udbetales overarbejdsbetaling for timer lagt udover de aftalte."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div v-if="employee.workHours < 37 || this.employee.maxWorkingHours" class="vx-row">
          <div class="vx-col sm:w-1/5 w-full">Hvordan er arbejdstiden fordelt?</div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-textarea
                v-model="employee.workHoursPlaced"
                class="w-full"
                placeholder="Beskriv arbejdstidens fordeling"
              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Ansættelsesdato</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              v-model="employee.employmentDate"
              type="date"
              class="w-full"
              :danger="
                $v.employee.employmentDate.$dirty &&
                !$v.employee.employmentDate.required
              "
              danger-text="Ansættelsesdato skal udfyldes"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Er stillingen tidsbegrænset?</span>
          </div>

          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="radioTerminationDate"
                vs-name="radioTerminationDate"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="radioTerminationDate"
                vs-name="radioTerminationDate"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
            </div>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiver om stillingen er tidsbegrænset eller ej. Er stillingen tidsbegrænset, skal der vælges en fratrædelsesdato."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div v-if="radioTerminationDate" class="vx-row mb-4">
          <div class="vx-col sm:w-1/5 w-full">Fratrædelsesdato?</div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-input
                v-model="employee.terminationDate"
                class="w-full"
                type="date"
              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Timelønnet?</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="radioHourlyPaid"
                vs-name="radioHourlyPaid"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="radioHourlyPaid"
                vs-name="radioHourlyPaid"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
            </div>
            <span v-if="radioHourlyPaid === null && dirtyHourlyPaid">
              <div style="color: #f17979">Timelønnet skal udfyldes</div>
            </span>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Angiver om stillingen lønnes pr. time. Hvis dette er tilfældet, skal den aftalte timeløn angives. Hvis der vælges nej, er det muligt at angive den aftalte månedsløn."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div v-if="radioHourlyPaid" class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full" style='padding-left:30px;'>
            <span>Aftalt timeløn</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              type="number"
              class="w-full"
              v-model="employee.hourlyPaid"
              placeholder="Indtast aftalt timeløn"
              :danger="
                $v.employee.hourlyPaid.$dirty &&
                !$v.employee.hourlyPaid.required
              "
              danger-text="Aftalt timeløn skal udfyldes"
            />
          </div>
        </div>

        <div v-if="radioHourlyPaid === false" class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full" style='padding-left:30px;'>
            <span>Aftalt månedsløn</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              class="w-full"
              v-model="employee.customSalary"
              placeholder="Indtast aftalt månedsløn"
              :danger="
                $v.employee.customSalary.$dirty &&
                !$v.employee.customSalary.required
              "
              danger-text="Aftalt månedsløn skal udfyldes"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Er den aftalte løn inkl. valgfrit lønelement?</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="employee.salaryPercentageIncluded"
                vs-name="radioSalaryPercentage"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="employee.salaryPercentageIncluded"
                vs-name="radioSalaryPercentage"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
            </div>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Tilbydes der kun minimum grundløn, vil valgfrit lønelement altid være eksklusivt. Er du i tvivl er du velkommen til at kontakte Domeas juridiske afdeling."
             color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div v-if="isServicecenter">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Behov for bil?</span>
            </div>
            <div class="vx-col sm:w-2/5 w-full">
              <div class="center">
                <vs-radio
                  v-model="employee.behovForBil"
                  vs-name="radiobehovForBil"
                  :vs-value="true"
                  class="pr-8">
                  JA
                </vs-radio>
                <vs-radio
                  v-model="employee.behovForBil"
                  vs-name="radiobehovForBil"
                  :vs-value="false">
                  NEJ
                </vs-radio>
              </div>
            </div>
            <div class="vx-col sm:w-1/5">
              <vs-tooltip title="Medarbejderen skal kunne stille egen bil til rådighed. Denne viden er alene til brug for kontrakten."
              color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Skal medarbejderen tildeles et anciennitetstillæg?</span>
            </div>
            <div class="vx-col sm:w-2/5 w-full">
              <div class="center">
                <vs-radio
                  v-model="employee.anciennitetsTillæg"
                  vs-name="radioanciennitetstillæg"
                  :vs-value="true"
                  class="pr-8">
                  JA
                </vs-radio>
                <vs-radio
                  v-model="employee.anciennitetsTillæg"
                  vs-name="radioanciennitetstillæg"
                  :vs-value="false">
                  NEJ
                </vs-radio>
              </div>
            </div>
            <div class="vx-col sm:w-1/5">
              <vs-tooltip title="Medarbejdere med 4 års anciennitet fra en boligorganisation der er medlem af BL, har krav på anciennitetstillæg."
              color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
            </div>
          </div>
          <div v-if="employee.anciennitetsTillæg" class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full" style='padding-left:30px;'>
              <span>Er anciennitetstillæg indregnet i den samlede angivne løn?</span>
            </div>
            <div class="vx-col sm:w-2/5 w-full">
              <div class="center">
                <vs-radio
                  v-model="employee.anciennitetsTillægIndregnet"
                  vs-name="radioanciennitetstillægindregnet"
                  :vs-value="false"
                  class="pr-8">
                  JA
                </vs-radio>
                <vs-radio
                  v-model="employee.anciennitetsTillægIndregnet"
                  vs-name="radioanciennitetstillægindregnet"
                  :vs-value="true">
                  NEJ
                </vs-radio>
              </div>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Skal medarbejderen tildeles et kvalifikationstillæg?</span>
            </div>
            <div class="vx-col sm:w-2/5 w-full">
              <div class="center">
                <vs-radio
                  v-model="employee.kvalifikationsTillæg"
                  vs-name="radiokvalifikationsTillæg"
                  :vs-value="true"
                  class="pr-8">
                  JA
                </vs-radio>
                <vs-radio
                  v-model="employee.kvalifikationsTillæg"
                  vs-name="radiokvalifikationsTillæg"
                  :vs-value="false">
                  NEJ
                </vs-radio>
              </div>
            </div>
            <div class="vx-col sm:w-1/5">
              <vs-tooltip title="Følgende kriterier skal være opfyldt: svendebrev, autorisation, anciennitet fra en lign. Stilling min 7 år."
              color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
            </div>
          </div>
          <div v-if="employee.kvalifikationsTillæg" class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full" style='padding-left:30px;'>
              <span>Er kvalifikationstillæg indregnet i den samlede angivne løn?</span>
            </div>
            <div class="vx-col sm:w-2/5 w-full">
              <div class="center">
                <vs-radio
                  v-model="employee.kvalifikationsTillægIndregnet"
                  vs-name="radiokvalifikationstillægindregnet"
                  :vs-value="false"
                  class="pr-8">
                  JA
                </vs-radio>
                <vs-radio
                  v-model="employee.kvalifikationsTillægIndregnet"
                  vs-name="radiokvalifikationstillægindregnet"
                  :vs-value="true">
                  NEJ
                </vs-radio>
              </div>
            </div>

          </div>

        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Er den aftalte løn inkl. pension?</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="employee.pension"
                vs-name="radioPension"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="employee.pension"
                vs-name="radioPension"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
              <span v-if="employee.pension == undefined && dirtyPension">
                <div style="color: #f17979">Pension skal udfyldes</div>
              </span>
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Er det aftalt at prøvetiden fravælges?</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="employee.probation"
                vs-name="radioProbation"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="employee.probation"
                vs-name="radioProbation"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
            </div>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Det er muligt at fravige den i funktionærloven bestemte prøvetid på tre måneder."
            color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <!-- 1) Medarbejderens (angiv i samlede antal måneder udover funktionærlovens opsigelsesvarsel) -->
        <!-- 2) Arbejdsgivers  (angiv i samlede antal måneder udover funktionærlovens opsigelsesvarsel) -->
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Aftalt forlænget opsigelsesvarsel</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="employee.extendedNoticeOfTermination"
                vs-name="radioExtendedNoticeOfTermination"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="employee.extendedNoticeOfTermination"
                vs-name="radioExtendedNoticeOfTermination"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
            </div>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Det er muligt at opsigelsesvarslet for begge parter forlænges udover det i funktionærloven bestemte. Her skal det angives hvor mange måneder medarbejders opsigelsesvarsel forlænges med samt hvor mange måneder arbejdsgivers opsigelsesvarsel forlænges med. Husk at forlængelsen skal være både rimelig og proportionel, ellers kan den senere erkendes ugyldig."
            color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div v-if="employee.extendedNoticeOfTermination" class="vx-row mb-4">
          <div class="vx-col sm:w-1/5 w-full"></div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="mb-4">
              (angiv i samlede antal måneder udover funktionærlovens
              opsigelsesvarsel)
            </div>
            <div class="center">
              <vs-input
                type="number"
                v-model="employee.extendedNoticeOfTerminationEmployee"
                class="w-full mb-2"
                placeholder="Indtast medarbejderens"
              />

              <vs-input
                type="number"
                v-model="employee.extendedNoticeOfTerminationEmployer"
                class="w-full"
                placeholder="Indtast arbejdsgivers"
              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Mobiltelefon</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-radio
              v-model="employee.transferPhoneNumber"
              vs-name="radioTransferPhoneNumber"
              :vs-value="true"
              class="pr-8"
            >
              Overføres
            </vs-radio>
            <vs-radio
              v-model="employee.transferPhoneNumber"
              vs-name="radioTransferPhoneNumber"
              :vs-value="false"
              class="pr-8"
            >
              Nyt nummer
            </vs-radio>
            <vs-radio
              v-model="employee.transferPhoneNumber"
              vs-name="radioTransferPhoneNumber"
              :vs-value="null"
            >
              Fravalgt
            </vs-radio>
            <vs-input v-if="employee.transferPhoneNumber"
              class="w-full"
              v-model="employee.workPhoneNumber"
              placeholder="Indtast nummer som skal overføres"
              :danger="($v.employee.workPhoneNumber.$dirty && employee.transferPhoneNumber && (!$v.employee.workPhoneNumber.required  || employee.workPhoneNumber.length != 8))"
              danger-text="Mobilnummer skal udfyldes, 8 cifre"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Bredbånd</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-checkbox v-model="employee.broadband" label-before>
              Medarbejder tilbydes bredbånd
            </vs-checkbox>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Det skal angives om der til stillingen skal være arbejdsgiver betalt bredbånd til medarbejders privatadresse."
            color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>
        
        <div v-if="newProfile" class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Hardware</span>
            <br/>
            <span style="font-style:italic">(Sæt kun 'hak' ved udstyr som skal bestilles)</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full" >
            <b>{{ newProfile.hardware}}</b><br/>
            <ul >
              <li v-for="item in currentDevices"  :key="item.name" style='padding-top:5px;'>
                <vs-checkbox v-model="item.checked"  >{{item.name}}</vs-checkbox>
              </li>
            </ul>
            <br>
            <b>Ekstra hardware tilvalg</b><br/>
            <ul >
              <li v-for="item in currentAddons" :key="item.navn" style='padding-top:5px;'>
                <vs-checkbox v-model="item.checked"  >{{item.navn}} ( {{item.pris}} )</vs-checkbox>
              </li>
            </ul>
            <!--<div v-if="newProfile.hardwareTilvalg"><br/>Til denne stilling hører desuden: {{ newProfile.hardwareTilvalg }}</div> -->
          </div>
        </div>

        <div v-if="newProfile" class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">Konteringsreference</div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-input
                v-model="employee.konteringsReference"
                class="w-full"
                placeholder="Indtast orderreference til brug for kontering"
                :danger="($v.employee.konteringsReference.$dirty && !$v.employee.konteringsReference.required)"
                danger-text="Konteringsreference skal udfyldes"

              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Straffeattest indhentet</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-select
              placeholder="Vælg"
              v-model="employee.straffeattest"
              class="profile-prompt-select"
              autocomplete>
              <vs-select-item
                v-for='(item, index) in [{ text: "Ja", value: "Ja" },{ text: "Nej", value: "Nej" },{ text: "Ikke relevant", value: "Ikke relevant" }]'
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-1/5 w-full">Specielle aftaler</div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-textarea
                v-model="employee.specielleAftaler"
                class="w-full"
                placeholder="Eventuelt specielle aftaler"
              />
            </div>
          </div>
        </div>

      
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Benytter I indkøbssystemet?</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <div class="center">
              <vs-radio
                v-model="employee.benytterIndkobsSystem"
                vs-name="radioBenytterIndkobsSystem"
                :vs-value="true"
                class="pr-8"
              >
                JA
              </vs-radio>
              <vs-radio
                v-model="employee.benytterIndkobsSystem"
                vs-name="radioBenytterIndkobsSystem"
                :vs-value="false"
              >
                NEJ
              </vs-radio>
            </div>
          </div>
          <div class="vx-col sm:w-1/5">
            <vs-tooltip title="Vælg JA, hvis brugeren skal varetage nogle opgaver i indkøsbsystem."
            color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
          </div>
        </div>

        <div v-if="employee.benytterIndkobsSystem" class="vx-row mb-4">
          <div class="vx-col sm:w-1/5 w-full"></div>
          <div class="vx-col sm:w-4/5 w-full" style="margin-bottom: 25px;">
                Hvilken rolle skal medarbejderen have (minimum 1 rolle er påkrævet)
          </div>
          <div class="vx-col sm:w-1/5 w-full"></div>
          <div class="vx-col sm:w-2/5 w-full"> 
              
              <vs-checkbox v-model="employee.rekvirent" style="padding-top:5px;" label-before>
                Rekvirent
              </vs-checkbox>
              <vs-checkbox v-model="employee.lokalAftaleAnsvarlig" style="padding-top:5px;" label-before>
                Lokal aftaleansvarlig
              </vs-checkbox>
              <vs-checkbox v-model="employee.superBruger" style="padding-top:5px;" label-before>
                Superbruger
              </vs-checkbox>
              <vs-checkbox v-model="employee.laeseAdgang" style="padding-top:5px;" label-before>
                Læseadgang
              </vs-checkbox>
              
              <div v-if="employee.rekvirent">
                <div class="mb-4" style="margin-top: 25px;">
                  Prokura (Beløb der kan indkøbes for - Max beløb: {{ prokura }})
                </div>
                <vs-input type="number" 
                  v-model="employee.maxProkura" 
                  @input="() => {if (employee.maxProkura > prokura || employee.prokura < 0) {employee.maxProkura = prokura }}"
                  class="w-full mb-2"
                  placeholder="Indtast beløb der kan indkøbes for" 
                  max="1000"
                  style="margin-top: 15px;"
                />   
              </div>              
          </div>
            
            <!--<div class="mb-4">
              (Vælg 1  eller flere)
            </div> -->
            <div class="vx-col sm:w-1/5">

              <div class="vx-col sm:w-1/5">
              <vs-tooltip title="kan købe ind for de finansenheder, der er givet adgang til."
                color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
              </div>
              <div class="vx-col sm:w-1/5">
              <vs-tooltip title="adgang til aftalemodulet og kan oprette aftaler."
                color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
              </div>
              <div class="vx-col sm:w-1/5">
              <vs-tooltip title="har udvidede rettigheder."
                color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
              </div>
              <div class="vx-col sm:w-1/5">
              <vs-tooltip title="Kan fremsøge allerede oprettede ordrer, men kan ikke bestille."
                color="#005446" class="material-icons domeaGreen">info</vs-tooltip>
              </div>
                            
            </div>
        </div>

      </vx-card>
      </fieldset>
      <!-- Register Employee and back button -->
      <div class="mt-4">
        <backbutton></backbutton>

        <vs-button v-if="!this.approvedByJura"
          @click="submit"
          id="save-button"
          class="ml-4 mr-auto mt-2"
          size="medium"
        >
          {{isAddMode ? "Opret medarbejder": "Opdater medarbejder"}}
        </vs-button>
      </div>

    </form>
  </div>
</template>

<script>
import { required, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  props: {
    employeeId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      employee: {
        // Stamdata
        name: "",
        address: "",
        email: "",
        phoneNumber: "",
        cpr: "",
        profile: "",
        education: "",
        recruitedFrom: "",
        anciennitet: null,
        // Vilkår
        workHours: 37,
        workHoursPlaced: "",
        employmentDate: null,
        terminationDate: null,
        customSalary: "",
        salaryPercentageIncluded: null,
        pension: null,
        maxWorkingHours: null,
        flexjob: null,
        flexJobRemark: null,
        hourlyPaid: null,
        probation: null,

        extendedNoticeOfTermination: null,
        extendedNoticeOfTerminationEmployee: "",
        extendedNoticeOfTerminationEmployer: "",

        transferPhoneNumber: null,
        workPhoneNumber: "",
        broadband: null,
        devices:"",
        tilvalg: "",
        konteringsReference: "",
        specielleAftaler: null,
        straffeattest: null,
        // SC
        behovForBil: null,
        anciennitetsTillæg: null,
        anciennitetsTillægIndregnet: null,
        kvalifikationsTillæg: null,
        kvalifikationsTillægIndregnet: null,
        // SC slut

        //indkøbssystem        
        benytterIndkobsSystem: null,
        rekvirent: null,
        lokalAftaleAnsvarlig: null,
        superBruger: null,
        laeseAdgang: null,

        maxProkura: "",
        //indkøsbsystem slut
      },
      infoTexts: [],
      profiles: [],
      selectedDepartments: [],
      departments: null,
      //departmentsKeys: [],

      activePrompt: false,
      currentProfile: 0,
      newProfile: null,

      dirtyCurrentProfile: false,
      dirtyPension: false,
      dirtyHourlyPaid: false,

      query: "*",

      radioAnciennitet: null,
      radioPension: null,
      //radioMaxWorkingHours: null,
      radioFlexjob: null,
      radioHourlyPaid: null,
      radioProbation: null,
      radioTransferPhoneNumber: false,
      radioTerminationDate: null,
      radioSalaryPercentage: null,
      radioExtendedNoticeOfTermination: null,
      radioBenytterIndkobsSystem: null,

      //maxProkura: 500,

      //checkboxBroadband: false,

      recruitedFromSelected: "Ansættes udefra",
      recruitedFromOptions: [
        { text: "Ansættes udefra", value: "Ansættes udefra" },
        { text: "Genansættes", value: "Genansættes" },
        { text: "Allerede ansat", value: "Allerede ansat" },
      ],

      indkobsError: null,
    };
  },

  validations: {
    employee: {
      name: {
        required,
      },
      address: {
        required,
      },
      email: {
        required,
      },
      phoneNumber: {
        required,
      },
      cpr: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      education: {
        required,
      },
      konteringsReference: {
        required,
      },
      workPhoneNumber: {
        required: requiredIf(function() { return this.employee.transferPhoneNumber}),
        minLength: minLength(8),
        maxLength: maxLength(8),
      },
      employmentDate: {
        required,
      },
      workHours: {
        required,
      },
      hourlyPaid: {
        required: requiredIf(function() { return this.radioHourlyPaid})
      },
      customSalary: {
        required: requiredIf(function() { return !this.radioHourlyPaid})
      },
      pension: {
        required,
      },
    },
  },

  methods: {
    submit() {

      this.dirtyCurrentProfile = true;
      this.$v.$touch();

      // this.dirtyPension = this.employee.pension === null ? true : false;
      // this.dirtyHourlyPaid = this.employee.hourlyPaid === null ? true : false;

      //const temp = [...(this.currentProfiles ?? [])];
//      const index = temp.findIndex(v => v.profileId === this.newProfile);
 //     console.log(temp[index].type);

      if (this.$v.$invalid) { // && !this.newProfile
        console.log("Submitting form error");

        this.$vs.notify({
            time:10000,
            color: "danger",
            icon: "error",
            title: "Fejl!",
            text: "En eller flere felter mangler eller er udfyldt forkert.",
          });
          return;

      } else {
        if ((this.radioTerminationDate && this.employee.terminationDate == null) || this.dirtyPension === true || this.dirtyHourlyPaid) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Medarbejder kunne ikke oprettes",
          });
          return;
        }

        this.saveData();
      }
    },

    saveData: function () {
      console.log(process.env.VUE_APP_API + "/employees");

      this.$vs.loading();

      console.log('Indkøbssystem: ' + this.employee.benytterIndkobsSystem);

      var afdeling = this.selectedDepartments.toString();
      var afdelingsnavn = this.departments.find(entry => entry.departmentKey.toString() === this.selectedDepartments.toString()).departmentName;
      //return;
      var payload = {
        id: this.employee.employeeId,
        // Stamdata
        ansatFinansenhed: afdeling ? afdeling : "",
        ansatFinansenhednavn: afdelingsnavn ? afdelingsnavn : "Ikke fundet",

        name: this.employee.name === "" ? null : this.employee.name,
        address: this.employee.address === "" ? null : this.employee.address,
        email: this.employee.email === "" ? null : this.employee.email,
        phoneNumber: this.employee.phoneNumber === "" ? null : this.employee.phoneNumber,
        cpr: this.employee.cpr === "" ? null : this.employee.cpr,

        profile: this.newProfile.profileId,
        education: this.employee.education === "" ? null : this.employee.education,
        recruitedFrom: this.recruitedFromSelected,
        anciennitet: this.radioAnciennitet ? this.radioAnciennitet : null,
        // Vilkår
        workHours: this.employee.workHours.toString(),
        workHoursPlaced: this.employee.workHoursPlaced,
        employmentDate: this.employee.employmentDate,
        terminationDate: this.radioTerminationDate
          ? this.employee.terminationDate
          : null,
        customSalary: !this.radioHourlyPaid
          ? this.employee.customSalary === ""
            ? null
            : this.employee.customSalary
          : "",
        salaryPercentageIncluded: this.employee.salaryPercentageIncluded ? this.employee.salaryPercentageIncluded : false,
        pension: this.employee.pension ? this.employee.pension: false,
        maxWorkingHours: this.employee.maxWorkingHours,
        flexjob: this.employee.flexjob ? this.employee.flexjob : false,
        flexjobRemark:
          this.employee.flexjobRemark === ""
            ? null
            : this.employee.flexjobRemark,
        hourlyPaid: this.radioHourlyPaid ? this.employee.hourlyPaid : "",
        probation: this.employee.probation ? this.employee.probation : false,
        extendedNoticeOfTermination: this.employee.extendedNoticeOfTermination ? this.employee.extendedNoticeOfTermination : false,
        extendedNoticeOfTerminationEmployee: this.employee.extendedNoticeOfTermination ? this.employee.extendedNoticeOfTerminationEmployee : "",
        extendedNoticeOfTerminationEmployer: this.employee.extendedNoticeOfTermination ? this.employee.extendedNoticeOfTerminationEmployer : "",

        transferPhoneNumber: this.employee.transferPhoneNumber, // Må gerne være null
        workPhoneNumber: this.employee.workPhoneNumber,
        broadband: this.employee.broadband ? this.employee.broadband : false,

        devices: this.currentDevices ? this.currentDevices.filter(i => i.checked).map(i => i.name).join() : "",
        tilvalg: this.currentAddons ? this.currentAddons.filter(i => i.checked).map(i => i.navn +  " ( " + i.pris + " )" ).join() : "",
        //tilvalg : this.newProfile.hardwareTilvalg  ? this.newProfile.hardwareTilvalg : "",
        konteringsReference: this.employee.konteringsReference,
        specielleAftaler: this.employee.specielleAftaler,
        straffeattest: this.employee.straffeattest,
        // SC
        behovForBil: this.employee.behovForBil,
        anciennitetsTillæg: this.employee.anciennitetsTillæg,
        anciennitetsTillægIndregnet: this.employee.anciennitetsTillægIndregnet,
        kvalifikationsTillæg: this.employee.kvalifikationsTillæg,
        kvalifikationsTillægIndregnet: this.employee.kvalifikationsTillægIndregnet,
        // SC slut
        // Indkøb
        benytterIndkobsSystem: this.employee.benytterIndkobsSystem,
        rekvirent: this.employee.rekvirent ? this.employee.rekvirent : false,
        lokalAftaleAnsvarlig: this.employee.lokalAftaleAnsvarlig ? this.employee.lokalAftaleAnsvarlig: false,
        superBruger: this.employee.superBruger ? this.employee.superBruger : false,
        laeseAdgang: this.employee.laeseAdgang ? this.employee.laeseAdgang : false,
        maxProkura: this.employee.maxProkura,
        //Indkøb slut
      };

      console.log( JSON.stringify(payload) );

      if (this.employee.employeeId){
        console.log('Benytter Indkob 1: ' + this.employee.benytterIndkobsSystem);
        payload.organizationId = this.employee.organizationId,
        payload.departmentManagerId = this.employee.departmentManagerId,
        payload.departmentManagerName = this.employee.departmentManagerName,
        payload.departmentManagerEmail = this.employee.departmentManagerEmail,
        payload.state = this.employee.state,
        payload.stateUpdateDate = this.employee.stateUpdateDate,
        payload.departmentStatus = this.employee.departmentStatus
        payload.createDate = this.employee.createDate

      this.$http
        .put(process.env.VUE_APP_API + "/employees/" + this.employee.employeeId, payload)
        .then((resp) => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Medarbejder opdateret",
            });

            this.$router.push({ name: "employees" }).catch(() => {});
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Medarbejder kunne ikke opdateres: " + resp.data.error,
            });
          }
        })
        .catch((err) => {
          console.log(err);

          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Medarbejder kunne ikke oprettes",
          });
        })
        .finally(() => {
          this.$vs.loading.close();
        });
      }
      else{
        console.log('Benytter Indkob 2: ' + this.employee.benytterIndkobsSystem);

        this.$http
          .post(process.env.VUE_APP_API + "/employees", payload)
          .then((resp) => {
            if (resp.data.success === true) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Medarbejder oprettet",
              });

              this.$router.push({ name: "employees" }).catch(() => {});
            } else {
              console.log(resp.data.error);

              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: "Medarbejder kunne ikke oprettes: " + resp.data.error,
              });
            }
          })
          .catch((err) => {
            console.log(err);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Medarbejder kunne ikke oprettes",
            });
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    },

    getProfiles: function () {
      this.$vs.loading();
      this.roles = this.$store.state.roles;
      
      let route = `/profiles/myOrganizationProfiles`;
      // Admin skal se alle profiler.
      if (this.$store.state.roles.includes('Admin'))
      {
        route = "/profiles?query=" + this.query;
      }

      this.$http
        .get(process.env.VUE_APP_API + route)
        .then((resp) => {
          this.profiles = resp.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    getDepartments: function () {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + `/departments`)
        .then((resp) => {
          let organizations = resp.data.organisations;
          if (organizations) {
            this.departments = findOrganizationStructure(organizations);
            if(this.isAddMode){
              this.selectedDepartments = this.$store.state.userData.userOrganisation;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    getHardware: function () {
      this.$vs.loading();
      // Henter alle Hardware pakker. 
      this.$http
        .get(process.env.VUE_APP_API + `/hardware`)
        .then((resp) => {
          this.hardware = resp.data;
          console.log(`${resp.data.length} hardware fetched`);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    getHardwareOptions: function() {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/HardwareOptions?query=" + this.query)
        .then(resp => {
          this.hardwareOptions = resp.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    getProkuraMax: function(jobtitle) {
       console.log('prokura jobtitle: ' + jobtitle);
       switch (jobtitle){
        case 'Regionsdirektørernes':
          this.prokura = 1000000;
          break;
        case 'For KC':
          this.prokura = 500000;
          break;
        case 'For DL':
          this.prokura = 250000;
          break;
        case 'Ledende ejendomsmestre':
          this.prokura = 100000;
          break;
        default: 
          this.prokura = 25000;
          break;
          /*
        case 'Servicekoordinator':
          break;
        case 'Økonomikonsulenter':
          break;*/                  
       }
    },

    getInfoTexts: function () {
      //this.$vs.loading();

      this.infoTexts = [{ key: 'Adresse', text: 'Adresse hjælpetekst som skal defineres.....'}
      , { key: 'Navn', text: 'Navn hjælpetekst som skal defineres'}
      , { key: 'Jobtitel', text: 'Vælg jobtitel til medarbejderen fra listen'}
      , { key: 'Email', text: 'Email hjælpetekst som skal defineres'}
      ];
    },
    
    getInfoTextsByKey: function(findKey) {
      
      let tempInfo = this.infoTexts
      tempInfo = tempInfo.filter((item) => {
        return item.key.includes(findKey)
      })

      return tempInfo[0] ? tempInfo[0].text : 'Undefined';
    },

    formatDateForCalendar: function(dbDate){
      return moment(String(dbDate)).format("YYYY-MM-DD");
    },

  },
  watch: {
    newProfile(value){
      console.log('profile value', value);
      console.log(this.newProfile.type);

      this.getProkuraMax(this.newProfile.type);
      //const temp = [...(this.currentProfiles ?? [])];
      //const index = temp.findIndex(v => v.profileId === this.newProfile.profileId);
      //console.log(temp[index].type);
    }
  },
  computed:{
    isAddMode() {
      return !this.employeeId;
    },
    //Find hardware pakke ud fra det angivne i profilen
    currentHardware(){
      return this.hardware ? this.hardware.filter(item => this.newProfile.hardware.toLowerCase() === item.name.toLowerCase())[0]: null},
    // retuner en liste af devices i den aktuelle hardware pakke, med 'checkboks'. Default true.
    currentDevices(){
      console.log('Current Devices:');                                                                        
      console.log(this.employee.profile);
      
      return this.currentHardware ? this.currentHardware.indhold.map(i => ({name:i, checked: !this.isAddMode ? this.employee.devices.indexOf(i) >= 0 : false}), this): ""},
    //Alle device muligheder undtagen dem som allerede er med i standardpakken.
    allHardwareOptions(){
      const result = [];

      const data1 = this.hardwareOptions;
      const data2 = this.currentDevices;

      data1.forEach((item) => 
      {
        const matchedItem = data2.find((item1) => item1.name === item.navn + " ( " + item.pris + " )" );
        if (!matchedItem)
        {
          result.push(item);
        }
      });
      console.log(result);

      return result;
    },  

    /*
    profile(){
      return this.getProkuraMax(this.newProfile.type);
    },*/

    // Returner kun de profiler som tilhører den valgte afdeling.
    currentAddons(){
      console.log(this.employee.tilvalg);
      return this.allHardwareOptions ? this.allHardwareOptions.map(i => ({navn:i.navn, pris:i.pris, checked: !this.isAddMode ? this.employee.tilvalg == null ? false : this.employee.tilvalg.indexOf(i.navn) >= 0 : false}), this) : ""},
    currentProfiles(){      
      return this.profiles && this.selectedDepartments ? this.profiles.filter(item => item.organisationsId.toString() === this.selectedDepartments.toString() 
                                                                            || item.organisationsId.toString() === '0'
                                                                            || (item.organisationsId.toString() === '650' && this.selectedDepartments.toString().startsWith('650'))): null},
    // Bruges til at tjekke om specielle felter skal vises.
    isServicecenter(){
      return this.newProfile ?  this.newProfile.overenskomst.toLowerCase() === 'esl' : false},
    // Tjek om Jura har godkendt. Derefter må der ikke rettes.
    approvedByJura(){
      return this.employee.departmentStatus && this.employee.departmentStatus.some(d => d.name === 'Jura' && d.state === 3)
    }
  },
  created() {
    this.getProfiles();
    this.getHardware();
    this.getHardwareOptions();
    this.getDepartments();
    this.getInfoTexts();
    this.getProkuraMax('default'); //default

    if (!this.isAddMode) {
      this.$vs.loading();

      this.$http
        .get(
          process.env.VUE_APP_API + "/employees?query=" + this.$props.employeeId
        )
        .then(resp => {
          this.employee = resp.data.find(employee => employee.employeeId == this.$props.employeeId);
          this.employee.employmentDate = this.formatDateForCalendar(this.employee.employmentDate);
          this.radioTerminationDate = this.employee.terminationDate ? true: false;
          this.employee.terminationDate = this.formatDateForCalendar(this.employee.terminationDate);
          this.radioHourlyPaid = this.employee.hourlyPaid ? true: false;
          this.newProfile = this.profiles.find(profile => profile.profileId == this.employee.profile);
          this.selectedDepartments = this.employee.ansatFinansenhed;
          this.recruitedFromSelected = this.employee.recruitedFrom;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    }
  },
};

function findOrganizationStructure(obj) {
  const res = [];

  function recurse(obj) {
    for (const key in obj) {
      let value = obj[key];
      if (value != undefined) {
        if (value && typeof value === "object") {
          recurse(value, key);
        } else {
          if (key !== "name") {
            var departmentName = obj.name;
            var departmentKey = obj.key;
            res.push({ departmentName, departmentKey });
          }
        }
      }
    }
  }
  recurse(obj);
  return res;
}
</script>

<style lang="scss" scoped>
.horizontal-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
select {
  height: 40px;
  padding: 10px;
  border-color: lightgrey;
  border-radius: 5px;
  color: #626262;
  // 
}
.selector {
  width: 40% !important;
}
.selector select {
  width: 100% !important;
}
.profile-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.profile-prompt-select {
  width: 100%;
  // margin-top: 10px;
}
.domeaGreen {
    color: #005446;
}
fieldset {
  border:0;
}
// div {border-style: dotted;}
</style>
